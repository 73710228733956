.footerBackground {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
}

.copyrightIcon {
    position: absolute;
    height: 32%;
    top: 40%;
    left: 57px;
    max-height: 100%;
    width: 32px;
    overflow: hidden;
}

.copyrightLabel {
    margin: 0;
    position: absolute;
    height: 44%;
    top: 27%;
    left: 96px;
    font-size: inherit;
    line-height: 182.02%;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
}

.linkedinIcon,
.instagramIcon,
.githubIcon {
    position: absolute;
    height: 24%;
    top: 39%;
    max-height: 100%;
    width: 22.4px;
    cursor: pointer;
}

.linkedinIcon {
    right: 60px;
}

.githubIcon {
    right: 100px; /* Changed from 140px to 100px */
}

.instagramIcon {
    right: 140px; /* Changed from 100px to 140px */
}

.footerFrame {
    position: relative;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 0 20px; */
    box-sizing: border-box;
}

.footer {
    width: 100%;
    position: relative;
    margin: 0 !important;
    text-align: left;
    font-size: 24px;
    font-family: 'Open Sans';
}

/* Media query for small screens */
@media (max-width: 576px) {
    .footerBackground {
        height: 65px;
    }

    .footerFrame {
        height: 65px;
        padding: 0;
    }

    .copyrightIcon {
        height: 38.46%;
        top: 32.31%;
        left: 21px;
        width: 25px;
    }

    .copyrightLabel {
        height: 38.46%;
        top: 32.31%;
        left: 51px;
        font-size: 12px;
        width: 57%;
        display: flex;
        align-items: center;
    }

    .linkedinIcon {
        height: 36.92%;
        top: 32.31%;
        right: 25px;
        width: 22.4px;
    }

    .instagramIcon {
        height: 36.92%;
        top: 32.31%;
        right: 85px; /* Adjusted for responsiveness */
        width: 22.4px;
    }

    .githubIcon {
        height: 36.92%;
        top: 32.31%;
        right: 55px; /* Adjusted for responsiveness */
        width: 22.4px;
    }
}
