.contactSection {
    width: 100%;
    position: relative;
    height: 700px;
    text-align: left;
    font-size: 24px;
    font-family: 'Open Sans';
}

.contactFormBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
}

.nameInput,
.emailInput,
.messageInput {
    position: absolute;
    height: auto;
    width: 100%;
    max-width: 547px;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    box-sizing: border-box;
}

.nameInput {
    top: 7.41%;
    right: 48px;
}

.emailInput {
    top: 28.23%;
    right: 48px;
}

.messageInput {
    top: 47.16%;
    right: 48px;
    height: 34.23%;
}

.sendButtonBorder {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    mix-blend-mode: normal;
}
.sendButton:hover {
    background-color: #f1f1f1;
  
}

.sendLabel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;

}

.sendButton {
    position: absolute;
    width: 100%;
    max-width: 547px;
    height: 50px;
    margin-top: 20px;
    top: 83.75%;
    right: 48px;
    bottom: 6.15%;
    font-size: 32px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
   
}



.sendButton:focus {
    outline: none;
}

.pulse-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    animation: pulse 2s infinite ease-in-out;
    pointer-events: none;
    z-index: -1;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.7;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.3;
    }
    100% {
        transform: scale(0.9);
        opacity: 0.7;
    }
}

.nameLabel,
.emailLabel,
.messageLabel {
    position: absolute;
    font-weight: 600;
}

.nameLabel {
    top: 2.50%;
    right: 527px;
}

.emailLabel {
    top: 23%;
    right: 531px;
}

.messageLabel {
    top: 41.5%;
    right: 494px;
}

.contactForm {
    position: absolute;
    height: 90.57%;
    top: 4.71%;
    right: 26px;
    bottom: 4.71%;
    width: 654px;
}

.contactEmail {
    margin: 0;
    position: absolute;
    top: 485px;
    left: 54px;
    font-size: 16px;
    font-weight: bold;
}

.contactPhoneNo {
    position: absolute;
    top: 535px;
    left: 54px;
    font-size: 16px;
    font-weight: bold;
}

.contactMeDescription {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 32px;
    line-height: 26px;
    font-weight: 600;
    font-family: inherit;
}

.contactMeLabel {
    position: absolute;
    top: 8.64%;
    left: 0;
    font-size: 24.81px;
    display: inline-block;
    width: 457px;
}

.emailIcon,
.phoneIcon {
    position: absolute;
    width: 30px;
    height: 30px;
    overflow: hidden;
}

.emailIcon {
    top: 482px;
    left: 0;
}

.phoneIcon {
    top: 532px;
    left: 0;
}

.darkModeButtonBorder {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    

}


.darkModeLabel {
    position: absolute;
    height: 47.73%;
    width: 50.34%;
    top: 25%;
    left: 10.88%;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.darkModeButton {
    position: absolute;
    top: 416px;
    left: 0px;
    width: 147px;
    height: 44px;
    cursor: pointer;
    font-size: 12px;
    
}
.darkModeButton:hover{
    border: 1.5px solid #4573df;
}
.certificationBadgeIcon {
    position: absolute;
    top: 165px;
    left: 0px;
    width: 105px;
    height: 105px;
    object-fit: cover;
    transition: transform 0.3s ease;
}
.certificationBadgeIcon2 {
    position: absolute;
    top: 165px;
    left: 125px;
    width: 105px;
    height: 105px;
    object-fit: cover;
    transition: transform 0.3s ease;
}
.certificationBadgeIcon:hover,
.certificationBadgeIcon2:hover {
    transform: scale(1.1);
}
.contactDetails {
    position: absolute;
    top: 48px;
    left: 121px;
    width: 457px;
    height: 567px;
}

.message {
    position: absolute;
    top: 93%;
    right: 48px;
    font-size: 16px;
    font-weight: 600;

}



.successMessage {
    color: #34a853;
}

.errorMessage {
    color: #e81123;
}

/* Media query for tablets (min-width: 768px and max-width: 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {


    .certificationBadgeIcon,
    .darkModeButton,
    .contactDetails,
    .emailIcon,
    .phoneIcon,
    .darkModeButtonBorder,
    .buttonState,
    .buttonState1,
    .darkModeLabel {
        display: none;
    }
    .contactForm {
        position: absolute;
        height: 90.57%;
        top: 4.71%;
        right:20%;
        bottom: 4.71%;
        width: 654px;
    }

}
       


/* Media query for screens of width 576px and below */
@media (max-width: 576px) {
    .contactDetails {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }

    .contactSection {

        height: auto;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        padding-top: 30%;
        max-width: 90%;
    }

    .contactFormBackground {
        width: 100%;
        height: auto;
    }

/*   
    .contactEmail {
        position: absolute;
        padding-top: 570px;
        left: 0;
        padding-left:20%;
        font-size: 16px;
        text-align: left;
        margin-top: 10px;
        width: 95%;
        top: 30px;
        font-weight: normal;
        margin-bottom: 10px;
        margin-top: 6%;
    } */

    /* .contactPhoneNo {
        position:absolute;
        font-weight: bold;
        position: relative;
        padding-top: 580px;
        margin-top: 3%;
        top: 0px;
        left: 0px;
        padding-left:20%;
        font-size: 16px;
        font-weight: lighter;
    } */


    .contactMeDescription {
        position: absolute;
       padding-top: 20px;
        padding-left:8%;
        font-size: 24px;
        line-height: 26px;
        font-weight: 600;
        text-align: left;
        margin-top: 0px;
        width: 100%;
        top:0px;
    }


    .doYouHave {
        margin:0px;
        padding-top:55px;
        line-height: 20px;
        font-size: 22px;
        padding-left:7%;
    }

    .doyouHave {
        
        margin:0px;
        padding-left:7%;
        padding-top: 6px;
        line-height: 22px;
        font-size: 20px;
        max-width:75%;
        display: flex;
        
    }
    /* .emailIcon {
        top: 610px;
        left: 10%;
        margin-top: 4%;
    }
    
    .phoneIcon {
        top: 580px;
        left: 10%;
        margin-top: 3%;
    } */
    
    .phoneIcon,
    .emailIcon,
    .contactPhoneNo,
    .contactEmail,
   .certificationBadgeIcon,
   .certificationBadgeIcon2,
   .darkModeButton,
   .darkModeButtonBorder,
   .buttonState,
   .buttonState1,
   .darkModeLabel {
       display: none;
   }


   .contactForm {
    
       position: relative;
       top: 20px;
       width: 100%;
       max-width: 430px;
       height: auto;
       padding: 0 15px;
       right: 0px;
       margin-bottom: 25px;
       box-sizing: border-box;
       border-radius: 10px;
       display: flex;
       flex-direction: column;
       align-items: center;
     
   }

   .nameInput,
   .emailInput,
   .messageInput {
       position: relative;
       width: calc(100% - 24px);
       height: auto;
       margin-bottom: 15px;
       padding: 12px;
       left: 0px;
       right: 0px;
       font-size: 16px;
       border-radius: 5px;
       box-sizing: border-box;
       align-items: center;
   }

   .sendButton {
       position: relative;
       width: 95%;
       height: 50px;
       left: 0px;
       right: 0px;
       margin-top: 20px;
       margin-bottom: 15px;
       border: none;
       border-radius: 5px;
       font-size: 18px;
       cursor: pointer;
       display: flex;
       align-items: center;
       justify-content: center;
   }

   .sendButtonBorder {
       height: 90%;
       width: 90%;

   }

   .sendLabel {
       font-size: 18px;
       font-weight: bold;
   }

   .nameLabel,
   .emailLabel,
   .messageLabel {
       position: relative;
       font-size: 14px;
       font-weight: bold;
       margin-bottom: 5px;
       display: block;
   }

   .nameLabel {
       margin-top: 20px;
   }

   .emailLabel {
       margin-top: 20px;
   }

   .messageLabel {
       margin-top: 20px;
   }

   .message {
       position: absolute;
       top: 88%;
   }

  





}